#mCI-container {
  position: relative;
}

#mCI-image-block {
  margin-top: 60px;

  position: relative;
}

#mCI-image-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  width: 88%;
  max-width: 380px;

  position: relative;
  left: 100%;
  transform: translateX(-100%);
}

#mCI-image-header > div {
  width: 20px;
  height: 20px;

  background-color: #5ff85c;
  border-radius: 50%;
}

#mCI-image-header > span {
  color: #b4b4b4;
  font-family: Reem Kufi;
  font-size: 15px;
  font-weight: 400;
}

.mCI-image-box {
  margin-bottom: 100px;

  width: 88%;
  max-width: 380px;

  position: relative;
  left: 100%;
  transform: translateX(-100%);
}

.mCI-image-box > img {
  width: 100%;
}

.mCI-image-box > button {
  margin-top: 60px;
  margin-bottom: 115px;

  width: 170px;
  height: 30px;
  padding: 0;

  border-radius: 30px;
  border: none;
  background-color: #5ff85c;

  color: #1c1e1c;
  text-align: center;
  font-family: Gmarket Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
}

.mCI-description {
  width: 88%;
  max-width: 380px;

  word-break: keep-all;

  position: relative;
  left: 100%;
  transform: translateX(-100%);
}

.mCI-description > span {
  color: #ddd6d6;
  font-family: Gmarket Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

#mCI-container.align-left #mCI-image-header {
  left: 0;
  transform: translateX(0);
}

#mCI-container.align-left .mCI-image-box {
  left: 0;
  transform: translateX(0);
}

#mCI-container.align-left .mCI-description {
  left: 0;
  transform: translateX(0);
}
