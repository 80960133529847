#mContact-container {
  margin-top: 50px;
  margin-bottom: 300px;

  position: relative;
}

#mContact-container > div {
  width: 88%;
  max-width: 380px;

  position: relative;
  left: 100%;
  transform: translateX(-100%);
}

#mContact-container > div > div {
  margin-bottom: 30px;
}

.mContact-line {
  margin-bottom: 15px;

  word-break: keep-all;
}

.mContact-type {
  margin-right: 10px;

  color: #5ff85c;
  font-family: "Gmarket Sans";
  font-weight: 400;
  font-size: 13px;
}

.mContact-text {
  color: #ddd6d6;
  font-family: "Gmarket Sans";
  font-weight: 400;
  font-size: 13px;
}

.mContact-btn {
  display: block;

  width: 90px;
  height: 30px;

  background: #5ff85c;
  border-radius: 30px;
  color: #1c1e1c;

  font-family: Gmarket Sans;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  cursor: pointer;

  text-decoration: none;
}

#mContact-container.align-left > div {
  left: 0;
  transform: translateX(0);
}
