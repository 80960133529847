.Section-title {
  display: flex;
  flex-direction: row;
  color: #ddd6d6;
  font-family: "Reem Kufi";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  /* identical to box height */
  text-transform: uppercase;
}
