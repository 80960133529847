.Home {
  margin-bottom: 240px;

  height: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.Home-logo {
  position: relative;
  height: 240px;
}

.Home-logo > div {
  position: absolute;
  left: -5%;
  width: 100%;
  height: 100%;

  opacity: 0.3;
  background: radial-gradient(
    259.79% 119.87% at 7.33% 18.62%,
    #5ff85c 0%,
    rgba(95, 248, 92, 0) 100%
  );

  filter: blur(50px);
}

.Home-logo > img {
  position: relative;
  height: 100%;
}

.Home-description {
  height: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  font-family: "Gmarket Sans";
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
}
