.Service {
  display: flex;
  flex-direction: column;
}

.Service-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 110px 0;
}

.Service-capture {
  height: 746px;
  width: 370px;
  margin: 0 30px;
}

.Service-page.top-content > .Service-capture {
  display: flex;
  justify-content: center;
}

.Service-page.top-content > .Service-capture > img {
  width: 1200px;
  height: 800px;
}

.Service-description {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
}

.Service-description > h4 {
  margin: 0;

  color: #ddd6d6;
  font-family: GangwonEduPower;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
}

.Service-description > h5 {
  margin: 0;
  margin-bottom: -10px;

  color: #ddd6d6;
  text-align: left;
  font-family: Gmarket Sans;
  font-size: 24px;
  font-weight: 700;
}

.Squizrun-text {
  margin-top: 42px;
  width: 422px;
}

.Service-detail {
  margin-top: 45px;

  text-align: left;
  font-size: 24px;
  font-weight: 500;
  line-height: 45px;
  color: rgb(221, 214, 214, 0.7);
  font-family: Gmarket Sans;
}

.Service-star-logo {
  position: absolute;
}

.Service-players {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 110px;
  margin-bottom: -10px;

  width: 480px;
}

.Player-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 36px;
}

.Player-block > span {
  color: #48c71d;
  text-align: center;
  font-family: Gmarket Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 45px;
}

#Service-last-page {
  margin: 250px 0 350px;

  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 61.17%;
}

#Service-last-page > .Service-description {
  transform: translateY(-105%);
}

@media screen and (max-width: 1480px) {
  .Service-page.top-content {
    padding-left: 130px;
  }
  .Service-page.top-content > .Service-description {
    margin-left: 0 !important;
    align-items: flex-end;
  }
  .Service-page.top-content > .Service-description > .Service-detail {
    text-align: right;
  }
}
