.Main {
  min-width: 1200px;
  width: 100%;
  text-align: center;
  background-color: #121312;
  overflow: hidden;
}

.Main-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(18, 19, 18, 0.8);

  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
}

.Header-logo {
  width: 80px;
}

.Header-content {
  max-width: 1150px;
  width: 100%;
  height: 100px;
  padding: 0 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #ddd6d6;
}

.Header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.Menu-link {
  cursor: pointer;
  font-family: "Reem Kufi";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.Menu-active {
  color: #5ff85c;
}

.Main-content {
  margin: 0 auto;
  width: 1150px;
  padding-top: 100px;
  padding-bottom: calc(100vh - 1180px);
  font-size: calc(10px + 2vmin);
  color: #ddd6d6;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
