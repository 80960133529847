#partners {
  display: flex;
  align-items: center;
  gap: 50px;
}

.partner {
  flex: 1;
}

.partner > img {
  width: 100%;
}

.partner > span {
  color: #ddd6d6;
  font-family: Gmarket Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
}

#copyright {
  font-family: "Gmarket Sans";
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #ddd6d6;

  margin-top: 90px;
  margin-bottom: 20px;
}
