.Contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 300px;
}

.Contact-info {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Contact-line {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.Contact-type {
  /* devhead green */
  color: #5ff85c;
  margin-right: 100px;
}

.Contact-text {
  color: #ddd6d6;
  font-weight: 400;
}

.Contact-btn {
  width: 140px;
  height: 38px;
  margin-left: 45px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5ff85c;
  border-radius: 30px;
  color: #1c1e1c;
  line-height: 38px;
  padding-top: 5px;
  cursor: pointer;

  text-decoration: none;
}
