#mService-container {
  width: 100%;
}

#mService-logo-block {
  margin-top: 50px;

  width: 100%;
}

.mService-description-title {
  position: relative;
}

.mService-description-title > span {
  position: relative;
  left: 100%;

  display: inline-block;
  width: 88%;
  max-width: 380px;

  word-break: keep-all;
  color: #ddd6d6;
  font-family: GangwonEduPower;
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;

  transform: translateX(-100%);
}

.mService-description-content {
  position: relative;
}

.mService-description-content > span {
  position: relative;
  left: 100%;

  display: inline-block;
  width: 88%;
  max-width: 380px;

  word-break: keep-all;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: rgb(221, 214, 214, 0.7);
  font-family: Gmarket Sans;

  transform: translateX(-100%);
}

#mService-logo-image-box {
  position: relative;

  margin: 10px 0;
}

#mService-logo-image-box > img {
  position: relative;
  left: 100%;

  width: 88%;
  max-width: 380px;

  transform: translateX(-100%);
}

#mService-container.align-left .mService-description-title > span {
  left: 0%;

  transform: translateX(0);
}

#mService-container.align-left #mService-logo-image-box > img {
  left: 0%;

  transform: translateX(0);
}

#mService-container.align-left .mService-description-content > span {
  left: 0%;

  text-align: left;
  transform: translateX(0);
}

.mService-capture {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.mService-play-image {
  position: relative;
  width: 100%;
}

.mService-play-image > img {
  position: relative;
  left: 100%;

  width: 88%;
  max-width: 380px;

  transform: translateX(-100%);
}

#mService-container.align-left .mService-play-image > img {
  left: 0%;

  transform: translateX(0);
}

.mService-description {
  position: relative;
  width: 100%;
}

.mService-description > .mService-description-title {
  margin-top: 30px;
  margin-bottom: 10px;
}

.mService-description-content > span > img {
  width: 24px;

  margin-bottom: -2px;
  margin-right: 6px;
}

.mService-player-block {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 10px 0;
}

.mService-player-block > div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 120px;
}

.mService-player-block > span {
  color: #48c71d;
  font-family: Gmarket Sans;
  font-size: 20px;
  font-weight: 700;
}

#mService-last-capture {
  margin-top: 90px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#mService-background {
  width: 100vw;
  max-width: 500px;
  height: 0;
  padding-top: 61.17%;

  box-sizing: border-box;

  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 326px) {
  #mService-logo-block > p {
    font-size: 14px;
    line-height: 21px;
  }
  .mService-description > p {
    font-size: 14px;
    line-height: 21px;
  }

  .mService-description > p > img {
    width: 20px;

    margin-bottom: -2px;
    margin-right: 6px;
  }
}
