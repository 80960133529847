.mMain {
  position: relative;

  width: 100%;

  background-color: #121312;
  overflow: hidden;
  overscroll-behavior: none;
}

.mMenuBtn {
  position: fixed;
  top: 55px;
  left: 10px;
  z-index: 2;

  width: 40px;
  height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.25s all;
}

.mMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  padding-top: 110px;
  padding-left: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #ddd6d6;
  background-color: rgba(0, 0, 0, 0.5);

  transform: translateX(-100%);
  transition: 0.25s transform;
}

.mMenu-Link {
  display: flex;
  flex-direction: row;
  color: #ddd6d6;
  font-family: "Reem Kufi";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */
  text-transform: uppercase;
  margin-bottom: 30px;
}

.mMenu-active {
  color: #5ff85c;
}

.Mobile-container {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;

  transition: 0.25s all;
}

.Mobile-container.align-left {
  transform: translateX(50%);
}

#mCopyright {
  margin-bottom: 20px;

  position: relative;
}

#mCopyright > span {
  opacity: 0.5;

  display: inline-block;

  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #ddd6d6;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.Mobile-container.align-left #mCopyright > span {
  left: 0;
  transform: translateX(0);
}
