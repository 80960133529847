#mHome-container {
  padding-top: 250px;

  position: relative;
}

#mHome-container > div {
  position: relative;

  left: 50%;
  transform: translateX(-50%);
}

#mHome-container.align-left > div {
  left: 0%;
  transform: translateX(0);
}

.mHome-logo {
  margin-bottom: 200px;

  width: 229px;
  height: 105px;
}

.mHome-logo > img {
  width: 100%;
  height: 100%;
}

.mHome-description {
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 22px;

  font-family: "Gmarket Sans";
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ddd6d6;
}
