.mPartners {
  position: relative;
}

.mPartners > div {
  display: grid;
  grid-template-columns: repeat(3, 90px);
  column-gap: 5px;
  row-gap: 20px;

  margin-top: 50px;

  position: relative;

  width: fit-content;

  left: 50%;
  transform: translateX(-50%);
}

.mPartners.align-left > div {
  left: 0;
  transform: translateX(0);
}

.mPartners-logo {
  min-width: 90px;
  height: 64px;
  background: #1c1e1c;
  border-radius: 0 83px 83px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.mPartners-logoimg {
  width: 80px;

  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  color: #ddd6d6;
}

@media screen and (max-width: 320px) {
  .mPartners > div {
    grid-template-columns: repeat(2, 90px);
  }
}

@media screen and (min-width: 420px) {
  .mPartners > div {
    grid-template-columns: repeat(4, 90px);
  }
}

@media screen and (min-width: 570px) {
  .mPartners > div {
    grid-template-columns: repeat(5, 90px);
  }
}
