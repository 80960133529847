.mSection-container {
  padding-top: 160px;
}

.mSection-container > h2 {
  margin: 0;
  width: 100%;

  color: #ddd6d6;
  font-family: "Reem Kufi";
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */
  text-transform: uppercase;
  text-align: left;
}
