.CI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CI-description {
  margin-bottom: 450px;
  width: 100vw;
  min-width: 1200px;
  display: flex;
  background-color: #1c1e1c;
  align-items: center;
  justify-content: center;
}
